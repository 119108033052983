import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PublicLayout from "./publicLayout";
import PrivateLayout from "./privateLayout";
import { useAuth } from "../context/auth";

import Download from "./Download/Download";
import { authClient } from "../utils/axios-utils";
import { useQuery } from "react-query";
import Reloader from "./Reloader/Reloader";
import EmbedVideo from "../pages/Embed/embedVideo/embedVideo";
import { Suspense } from "react";
import { lazy } from "react";
import WorkspaceInvite from "../pages/Dashboard/Workspace/Invite/workspaceInvite";

const SignupPage = lazy(() => import("../pages/SignUpPage/signup"));
const Dashboard = lazy(() => import("../pages/Dashboard"))
const Publish = lazy(() => import("./Publish/Publish"));
const Confirmation = lazy(() => import("../pages/SignUpPage/Confirmation/Confirmation"));
const ForgotPassword = lazy(() => import("../pages/LoginPage/ForgotPassword/ForgotPassword"));
const VerifyEmail = lazy(() => import("../pages/LoginPage/VerifyEmail/VerifyEmail"));
const Removeduser = lazy(() => import("../pages/Dashboard/Homepage/Removeduser"));
const Permission = lazy(() => import("./PermissionIssue/permission"));
const Login = lazy(() => import("../pages/LoginPage/login"));
const Pagenotfound = lazy(() => import("./PageNotFound/pagenotfound"));
const AppRoute = () => {
  const auth = useAuth();
  const currentUser = () => {
    const config = {
      url: "/auth/api/getcurrentuser",
      method: "GET",
    };

    return authClient(config);
  };
  const handleSuccess = (data) => {
    auth?.authDispatch({
      type: "CURRENT_USER_UPDATE",
      user: data?.user,
      isAuthenticated: true,
    });
    window.clarity("set", "email", data?.user?.email)
    window.fpr("referral", { email: data?.user?.email })
  };
  const handleAcquireError = (err) => {
    auth?.authDispatch({
      type: "CURRENT_USER_UPDATE",
      user: null,
      isAuthenticated: false,
    });
  };

  const { isLoading } = useQuery("current-user", currentUser, {
    retry: false,
    // refetchInterval: 20000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: handleSuccess,
    onError: handleAcquireError,
  });
  // if (isLoading) return <Reloader />;
  return (
    <Router>
      {auth.progress > 0 && <Download progress={auth.progress} />}
      <Suspense fallback={<Reloader />}>
        <Routes>
          <Route
            path="/view/trim/:username/:parentId/:trimId"
            element={<Dashboard />}
          />
          <Route path="/view/video/:username/:id" element={<Dashboard />} />
          <Route path="/dashboard/htmlstep/:id" element={<Dashboard />} />

          <Route path="/view/capture/:id" element={<Dashboard />} />
          <Route path="/video/embed/:id" element={<EmbedVideo />} />
          <Route path="/access-denied" element={<Removeduser />} />
          <Route path="*" element={<Pagenotfound />} />
          <Route element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
          </Route>
          <Route element={<PrivateLayout />}>
            <Route path="/dashboard/home" element={<Dashboard />} />
            <Route path="/dashboard/library" element={<Dashboard />} />
            <Route path="/dashboard/library/my-videos" element={<Dashboard />} />
            <Route path="/dashboard/library/my-captures" element={<Dashboard />} />
            <Route path="/dashboard/home/video/:id" element={<Dashboard />} />
            <Route path="/dashboard/library/video/:id" element={<Dashboard />} />
            <Route path="/dashboard/edit/video/:id" element={<Dashboard />} />
            <Route
              path="/dashboard/trim/:parentId/:trimId"
              element={<Dashboard />}
            />
            <Route path="/dashboard/profile" element={<Dashboard />} />
            <Route path="/dashboard/favorites" element={<Dashboard />} />
            <Route path="/dashboard/trash" element={<Dashboard />} />
            <Route path="/dashboard/playlist/:id" element={<Dashboard />} />
            <Route path="/dashboard/payment" element={<Dashboard />} />
            <Route path="/dashboard/workspace" element={<Dashboard />} />
            <Route path="/dashboard/view/workspace/:id" element={<Dashboard />} />
            <Route path="/dashboard/shared-with-me" element={<Dashboard />} />
            <Route path="/dashboard/capture/:id" element={<Dashboard />} />
            <Route path="/dashboard/demo/:id" element={<Dashboard />} />
            <Route path="/dashboard/edit/capture/:id" element={<Dashboard />} />
            <Route path="/dashboard/publish-to-youtube" element={<Publish />} />
            <Route path="/dashboard/permission-issue" element={<Permission />} />
            <Route path="/workspace/invite/:id" element={<WorkspaceInvite />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoute;
